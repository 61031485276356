import { z } from 'zod'

export type Coordinates = { latitude: number, longitude: number }

export const CITIES = [
  'Bristol',
  'Edinburgh',
] as const

export type City = typeof CITIES[number]

export const CitySchema = z.enum(CITIES)

export const COORDINATES: Record<City, Coordinates> = {
  Bristol: { latitude: 51.45523, longitude: -2.59665 },
  Edinburgh: { latitude: 55.95206, longitude: -3.19648 },
} as const
