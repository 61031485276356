'use client'

import React from 'react'
import { z } from 'zod'
import { Box, Button, Link, Sheet, Stack, Typography } from '@mui/joy'
import { formatNumber } from '@/lib/formatters'
import { CitySchema } from '@/lib/cities'
import useLocalStorage from '@/lib/useLocalStorage'
import { getSearch, saveSearch } from './calculator/actions'
import { Address } from '@/lib/components/AddressAutocomplete'
import AddressSelector from '@/lib/components/AddressSelector'
import PropertyImage from '@/lib/PropertyImage'
import { Search } from '@prisma/client'
import { DEFAULT_LOCATION_INPUTS } from './default-location'
import { BlockImage } from './calculator/Content'

const SearchParamsSchema = z
  .object({
    city: CitySchema,
  })
  .partial()

export default function Home({
  searchParams,
}: {
  searchParams?: unknown
}) {
  const { value: searchId, setValue: setSearchId, ready: searchIdReady } = useLocalStorage('search-id')
  const [loading, setLoading] = React.useState(true)
  const [installersParam, setInstallersParam] = React.useState<string>(
    getInstallerParams(searchId, searchParams)
  )
  const [search, setSearch] = React.useState<Search | null>(null)

  React.useEffect(() => {
    if (!searchIdReady) {
      return
    }

    setInstallersParam(getInstallerParams(searchId, searchParams))

    if (searchId === null) {
      setLoading(false)
      return
    }

    getSearch(searchId).then(
      (search) => {
        if (search !== null) {
          setSearch(search)
        }
        setLoading(false)
      },
      (error) => {
        console.error(error)
        setLoading(false)
      },
    )
  }, [searchId, searchIdReady, searchParams])

  async function onAddressChange(address: Address) {
    setLoading(true)
    const search = await saveSearch(address)

    setSearchId(search.id)
    setSearch(search)
    setLoading(false)
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', maxWidth: 'sm', paddingX: 1, paddingTop: 2 }}>
      <PropertyBlock search={search} onAddressChange={onAddressChange} ready={!loading} />

      <Typography level="h3" width="100%" marginY={2}>Calculate energy savings...</Typography>
      <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
        <SavingsBlock
          imgSrc="/home_fabric.png"
          imgAlt="An orangutan insulating its home"
          calculator="fabric"
          title="Insulation"
          savings={811}
        />
        <SavingsBlock
          imgSrc="/home_heating.png"
          imgAlt="An orangutan tuning its heating system"
          calculator="heating"
          title="Heating system"
          savings={137}
        />
        <SavingsBlock
          imgSrc="/home_usage.png"
          imgAlt="An orangutan living smartly"
          calculator="usage"
          title="Daily routine"
          savings={451}
        />
        <SavingsBlock
          imgSrc="/home_solar.png"
          imgAlt="An orangutan insulating its home"
          calculator="solar"
          title="Solar & battery"
          savings={630}
        />
      </Box>

      <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center" marginTop={5} marginBottom={2}>
        <Typography level="h3" width="100%">...or view local installers</Typography>
        <Button component="a" href={`/installers${installersParam}`} sx={{ whiteSpace: 'nowrap' }}>Find an installer</Button>
      </Stack>

      <Typography level="h3" width="100%" marginTop={5} marginBottom={2}>Orang Energy helps reduce your bills</Typography>
      <Stack direction="column" gap={2}>
        <Typography>
          By combining an energy savings calculator with a list of local installers, Orang Energy helps you reduce your energy bills!
        </Typography>

        <Typography>
          The calculator shows easy ways to cut your bills, such as draught proofing or simple changes to your daily routine.
        </Typography>

        <Typography>
          You can also explore property upgrades, such as adding loft insulation or installing a heat pump.
          These upgrades not only save you money every month but also increase the value of your home.
        </Typography>

        <Typography>
          Industry experts have created a detailed model of your home using data from your home’s EPC along with hourly regional climate data.
          For each upgrade, the calculator shows the estimated annual energy savings and a ballpark cost for the work based on your property details.
        </Typography>

        <Typography>
          Orang Energy also provides a list of local installers filtered by the types of services offered.
          Every installer listed has a profile and contact information.
          So once you’re happy with the calculator numbers, you can contact an installer to request a more accurate estimate.
        </Typography>

        <Typography>
          No sign-up or personal information is requested, so you get results in seconds, completely free.
        </Typography>

        <Typography>
          Ready to save money? Try Orang Energy now!
        </Typography>
      </Stack>
    </Box>
  )
}

function SavingsBlock({
  imgSrc,
  imgAlt,
  calculator,
  title,
  savings,
}: {
  imgSrc: string
  imgAlt: string
  calculator: string
  title: string
  savings: number
}): React.ReactNode {
  return (
    <Link href={`/calculator?calculator=${calculator}`} underline="none">
      <Box
        component={Sheet}
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 'xs',
          boxShadow: 'none',
        }}
      >
        <Stack direction="row">
          <Box flex="4">
            <BlockImage alt={imgAlt} src={imgSrc} />
          </Box>
          <Stack direction="column" flex="5" justifyContent="space-between" alignItems="center">
            <Typography textAlign="center">{title}</Typography>
            <Typography>{formatNumber(savings, 'currency')}</Typography>
          </Stack>
        </Stack>
      </Box>
    </Link>
  )
}

function PropertyBlock({
  ready,
  search,
  onAddressChange,
}: {
  ready: boolean
  search: Search | null
  onAddressChange: (_: Address) => Promise<void>
}): React.ReactNode {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const address = search?.address ?? DEFAULT_LOCATION_INPUTS.address
  const postcode = search?.postcode ?? DEFAULT_LOCATION_INPUTS.postcode

  async function handleAddressChange(address: Address) {
    setExpanded(false)
    await onAddressChange(address)
  }

  if (!ready) {
    return (
      <Sheet
        component={Stack}
        variant="outlined"
        sx={{ height: 159, boxShadow: 'none' }}
        justifyContent="center"
        alignItems="center"
      >
        Loading...
      </Sheet>
    )
  }

  return (
    <Sheet
      component={Stack}
      direction="column"
      gap={0.5}
      variant="outlined"
      borderRadius="xs"
      sx={{ boxShadow: 'none' }}
    >
      <Box sx={{
        height: 100,
        mask: 'linear-gradient(white, 85%, transparent)',
      }}
      >
        <PropertyImage
          address={address}
          postcode={postcode}
        />
      </Box>
      <Stack padding={1.5} direction="column">
        {!expanded
          ? (
            <Stack direction="row" gap={0.5} alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="left" gap={0} sx={{ minWidth: 0 }}>
                <Typography noWrap level="body-xs">
                  {address}
                  ,
                </Typography>
                <Typography level="body-xs" sx={{ minWidth: 72, textAlign: 'left' }}>
                  &nbsp;
                  {postcode}
                </Typography>
              </Stack>
              <Button
                size="sm"
                variant="plain"
                sx={{ minHeight: 0, padding: 0.5, whiteSpace: 'nowrap' }}
                color="primary"
                onClick={() => setExpanded(true)}
              >
                {search == null ? 'Enter your address' : 'Change'}
              </Button>
            </Stack>
            )
          : (
            <AddressSelector submit={handleAddressChange} />
            )}
      </Stack>
    </Sheet>
  )
}

function getInstallerParams(searchId: string | null, searchParams: unknown): string {
  const searchParamsParseResult = SearchParamsSchema.safeParse(searchParams)
  return searchId != null
    ? `?searchId=${searchId}`
    : searchParamsParseResult.data?.city != null
      ? `?city=${searchParamsParseResult.data.city}`
      : ''
}
